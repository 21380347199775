import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { getDashboard } from "@services/index";
import { BaseList } from "../base/list";
import { CreateManualLoadContainer } from "../manual-load/create-load";
import { NzModalRef } from "ng-zorro-antd/modal";
import { BizUtil } from "@services/biz";
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { InternalMessageService } from "@services/internal-message.service";
import { getInjector } from "@services/index";
import { PermissionCode } from "@const/PermissionCode";

interface TabData {
  key: string, name: string, query: any, total: number, hidden?: boolean
}
@Component({
  selector: 'carrier-sales-v2',
  templateUrl: './list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class CarrierSales extends BaseList {
  tabIndex = -1;
  public selectedItem = null
  public qParams: any
  private itemIds: string[] = [];
  tabs: TabData[] = [
    {
      key: 'bidding_session',
      name: 'Bidding Session',
      query: {},
      total: 0,
      hidden: !this.requirePermissions([PermissionCode.bid_session.read])
    },
    {
      key: 'needs_carrier',
      name: 'Needs Carrier',
      query: {},
      total: 0,
    },
    {
      key: 'not_accept_load_tender',
      name: 'Load Tender Not Accepted',
      query: {},
      total: 0,
    },
    {
      key: 'over_due',
      name: 'Over Due',
      query: {},
      total: 0,
      hidden: !this.requirePermissions([PermissionCode.bid_session.read])
    },
    {
      key: 'all',
      name: 'All',
      query: {},
      total: 0,
    },
  ];
  devTab: TabData;
  public devTabKeys = [ 'bid_not_queued', 'bid_queued', 'bid_sent_no_response', 'response_received', 'assigned_route'];
  public getDevTabNameByKey(key) {
    switch (key) {
      case 'bid_not_queued': return 'Not Queued';
      case 'bid_queued': return 'Queued';
      case 'bid_sent_no_response': return 'Sent - No Response';
      case 'response_received': return 'Response Received';
      case 'assigned_route': return 'Assigned Route';
    }
  }
  public isLoadingGhostShipment: boolean = true;
  get tabList() {
    return this.tabs.filter(it => !it.hidden);
  }

  // Dùng cho ReportRevenue
  public get isReportRevenue(): boolean { return false }
  public get canPerformAction(): boolean { return true }
  public get shouldShowTable(): boolean { return true }
  public get revenueSum(): any { return {} }
  onTabChange(event) {
    this.devTab = null;
    let tab = this.getTabData(event.index)
    this.paginationData.reset();
    this.tabIndex = event.index;
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    let currentFilter = JSON.parse(this.queryParams.filter);
    currentFilter.bidStage = tab?.key;
    q['filter'] = JSON.stringify(currentFilter);
    this.routeWithQueryUrl(q, true);
  }

  private getTabData(index: number): TabData {
    if (index >= 0 && index < this.tabList.length) {
      return this.tabList[index];
    } else if (this.devTab) { return this.devTab }
    return this.tabList[0];
  }
  
  constructor() {
    super();
    this.subscription.add(getInjector().get(InternalMessageService).getMessageCreateManualLoad().subscribe((message) => {
      if (message) {
        this.onBtnCreateManualLoad();
      }
    }));
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  getDefaultFilter = () => {
    if(this.requirePermissions([PermissionCode.bid_session.read])) return { bidStage: 'bidding_session', status: 'created' }
    return { bidStage: 'needs_carrier', status: 'created' }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const { bidStage } = this.queryParams;
    for (let index = 0; index < this.tabList.length; index++) {
      if (this.tabList[index].key === bidStage) {
        this.tabIndex = index
      }
    }
    if (this.devTabKeys.includes(bidStage)) {
      this.devTab = {
        key: bidStage,
        name: this.getDevTabNameByKey(bidStage),
        query: {},
        total: 0,
      }
      this.tabIndex = this.tabList.length;
    }

    if (!bidStage) {
      return this.routeWithQueryUrl({ bidStage: this.tabList[0].key });
    }

    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({ filter });
    }
    //nếu đổi filter thì reset lại list job
    if (JSON.stringify(prevQueryParam?.filter) != JSON.stringify(this.queryParams.filter)) {
      this.paginationData.reset()
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  loadData(query) {
    // dùng cho ReportRevenue
  }

  toggleTable() {
    // dùng cho ReportRevenue
  }

  public get queryParams() {
    return super.queryParams
  }

  protected getApiUrl(): string {
    return this.isShowListJob ? Const.APIURI_JOBS : Const.APIURI_BID_SESSIONS;
  }

  protected onGetDataSucceeded(resp) {
    this.tabs = this.tabs.map(it => {
      it.total = resp?.data?.summary?.[it.key] || 0;
      return it
    });
    if (this.devTab && resp?.data?.summary?.[this.devTab.key]) {
      this.devTab.total = resp?.data?.summary?.[this.devTab.key];
    }
    this.itemIds = [];
    let marketPlaceLoadIds = [];
    for (let item of this.listData) {
      this.itemIds.push(item.id);
      if(this.isMarketPlaceLoad(item)) marketPlaceLoadIds.push(item.id);
    }
    if(marketPlaceLoadIds.length){
      this.getGhostShipmentInfo(marketPlaceLoadIds);
    }
  }

  private isMarketPlaceLoad(item) {
    return item.type === WarpConst.JobType.ghost && item.source == WarpConst.JobSources.marketplace;
  }

  private getGhostShipmentInfo(marketPlaceLoadIds: string[]) {
    this.isLoadingGhostShipment = true;
    this.api.POST(`${Const.APIURI_MARKETPLACE()}/get_packaging_by_ids`, {ids: marketPlaceLoadIds}).subscribe(
      res => {
        for(let item of this.listData){
          if(marketPlaceLoadIds.includes(item.id)){
            let totalShipmentsItems = res.data.list_data.filter(it => (it.jobId === item._id))[0]?.totalShipmentsItems;
            item.totalShipmentsItems = totalShipmentsItems
          }
        }
        this.isLoadingGhostShipment = false;
      }
    )
  }

  onSearch(data = {}) {
    let tab = this.getTabData(this.tabIndex)
    const q: any = { bidStage: tab?.key, page: 1 }
    const search = this.queryParams.search ?? '';
    if (search) {
      q.search = search;
    }
    q['filter'] = JSON.stringify({ bidStage: tab?.key, ...data })
    this.routeWithQueryUrl(q, true);
  }

  public get isOnTabBidNotQueued(): Boolean {
    return this.tabIndex == 0;
  }

  public get isOnTabBidQueued(): Boolean {
    return this.tabIndex == 1;
  }

  public get isOnTabBidSent(): Boolean {
    return this.tabIndex == 2;
  }

  public get isOnTabBidReceived(): Boolean {
    return this.tabIndex == 3;
  }

  public get isOnTabAssigned(): Boolean {
    return this.tabIndex == 4;
  }

  public get isOnTabNotAcceptLoadTender(): Boolean {
    return this.tabIndex == 5;
  }

  public get isOnTabAll(): Boolean {
    return this.tabIndex == 6;
  }

  private onBtnCreateManualLoad() {
    DialogService.openFormDialog1(CreateManualLoadContainer, {
      nzClosable: false,
      nzMaskClosable: false,
      nzClassName: 'modal-fullscreen modal-no-padding',
      nzComponentParams: {
        onSubmitSucceeded: (resp) => {
          this.showDialogCreateManualLoadDone(resp.data);
          this.onBtnRefresh();
        }
      }
    });
  }

  @ViewChild('tplCreateManualLoadDone') tplCreateManualLoadDone: TemplateRef<any>;
  private dlgCreateManualLoadDone: NzModalRef;
  public dlgManualLoadDoneHyperLink;

  private showDialogCreateManualLoadDone(job) {
    this.dlgManualLoadDoneHyperLink = BizUtil.createHyperLinkForJob(job);
    this.dlgCreateManualLoadDone = this.modalService.create({
      nzContent: this.tplCreateManualLoadDone,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWrapClassName: 'dialog-manual-load-done'
    });
  }

  closeDialogCreateManualLoadDone() {
    this.dlgCreateManualLoadDone?.close();
  }

  get showAcceptedRate() {
    return this.isOnTabBidNotQueued || this.isOnTabAll || this.isOnTabBidSent || this.isOnTabBidReceived || this.isOnTabAssigned || this.isOnTabNotAcceptLoadTender;

  }

  get showQueued() {
    return this.isOnTabBidQueued
  }

  get isShowListJob(): boolean {
    const tab = this.getTabData(this.tabIndex);
    return !['bidding_session', 'over_due'].includes(tab.key);
  }
}
