import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Job } from "@wearewarp/types/data-model";
import { ResponseBidSessionListItem } from "@wearewarp/types/rest-api/admin/carrier-bid/bid-session";

@Component({
  selector: '[item-bid-session-component]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss'
  ]
})
export class ItemBidSessionComponent extends BaseComponent {
  private _item: ResponseBidSessionListItem;
  @Input() set item(val: ResponseBidSessionListItem) {
    this._item = val;
    // this._item.jobs = (this._item?.metadata?.jobs || []).map(job => {
    //   return {
    //     ...job,
    //     ...this.createHyperLinkForJob(job),
    //     pickupTime: DateUtil.displayLocalTime(job.pickupTime || new Date(), { format: 'DD MMM' }),
    //   }
    // })
  }

  get item() {
    return this._item;
  }

  isToggle = false;
  onToggleContent() {
    this.isToggle = !this.isToggle;
  }

  public displayTimeWindow() {
    const timezone = this.item?.timeZone || 'America/Los_Angeles';
    const window = { from: this.item.fromTime, to: this.item.toTime };
    const text = DateUtil.displayTimeWindow(window, {
      timezone: timezone,
      format: 'MMM DD, H:mm',
      formatDateOnly: 'MMM DD'
    });
    return `${text} (${DateUtil.timezoneStandardToUsShort(timezone)})`;
  }

  createHyperLinkForJob(job: Job) {
    const shipments = job.shipments || [];
    let firstShipment = shipments[0];
    let hyperLinkUrl = `${Const.routeAdminDispatchList}/${job.id}`;
    const clients = job.clients || [];
    const clientName = clients?.[0]?.name || 'Customer N/A';
    let hyperLinkText = `${clientName} / ${this.getMyWarpId(firstShipment)}`;
    if (shipments.length > 1) {
      hyperLinkText += ` (+${shipments.length - 1})`;
    }
    hyperLinkText += ` / Route ${job.code}`;
    return { hyperLinkText, hyperLinkUrl };
  }

  private getMyWarpId(data) {
    return data?.code || data?.warpId || data?.id || data?._id || 'Shipment N/A';
  }

  get countCandidate() {
    return this.item.count?.candidate || 0;
  }

  get countSentCandidate() {
    return this.item.count?.sent || 0;
  }

  get countAssigned() {
    return this.item.count?.alreadyAssigned || 0;
  }

  get countHasBids() {
    return this.item.count?.alreadyBid || 0;
  }

  get countNoBid() {
    return this.item.count?.notBid || 0;
  }

  get detailRouterLink() { return [Const.routeAdminBidSessions, this.item.id] }

  onAddRoute() {
    return this.router.navigate([Const.routeAdminBidSessions, this.item.id, 'add-routes']);
  }
}