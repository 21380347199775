import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { Utils } from "@services/utils";
import { SendDocsComponent } from "../../send-docs/send-docs.component";
import { DrawerService } from "@app/drawers/drawer.service";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { AddTargetRate } from "../../target-rate";
import { Log } from "@services/log";
import { SeeAllOrder } from "../../../components/see-all-orders/see-all-orders";
import { AddCarrierSaleRep } from "../../../dispatch/components/sales/add-carrier-sale-rep";
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { AssignCarrier } from "@app/admin/carrier-sales-v2/components/assign-carrier";
import { environment } from "@env/environment";
import moment from "moment";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: '[item-job-component]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss',
    '../../style.scss',
    '../../../list.scss'
  ]
})
export class ItemJobComponent extends BaseComponent {
  @Input() isLoadingGhostShipment: boolean = false;
  @Input() isOnTabNotAcceptLoadTender: boolean = false;
  @Input() showAcceptedRate: boolean = false;
  @Input() showQueued: boolean = false;
  @Input() isOnTabBidSent: boolean = false;
  @Input() isOnTabBidReceived: boolean = false;
  @Output() onBtnRefresh = new EventEmitter<any>();
  public get canPerformAction(): boolean { return true }
  public get canSendDocs(): boolean { return !this.isAdminReadOnlyRole }

  private _item: any;

  @Input() set item(data: any) {
    data.shipmentLinks = data.shipments?.map(item => {
      return {
        warpId: item.warpId,
        id: item.id,
        orderId: item.orderId
      }
    })

    data.onHold = data.shipments.filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).length > 0 || (data.tags?.length && data.tags.indexOf('HOLD') >= 0)
    data.isGhostLoad = data.type === WarpConst.JobType.ghost;
    data.isLinehaul = data.type === WarpConst.JobType.linehaul;

    data.pickWindow = this.displayWindow(Object.assign({ time: data.earliest_time, timeTo: data.latest_time }, data.pickDt));
    if (!data.dropDt) {
      data.dropDt = this.getDropDate(data.shipments);
      this.api.PUT(`${Const.APIURI_JOBS}/${data._id}/update_order_ralated_fields`, {}).subscribe(
        resp => {
        }, err => {
          // this.showErr(err);
          Log.e(err);
        }
      );
    }
    data.dropWindow = this.displayWindow(data.dropDt);
    data.isShowOnBidBoard = this.isShowOnBidBoard(data);
    if (this.canPerformAction) {
      const params = { canPerformAction: true };
      this.updateDatRate(data, params);
    }
    data.picName = this.getFullName(data?.pic) || null
    data.avgPastRates = data.avgPastRates ? InputHelper.formatMoney2(data.avgPastRates.toFixed(2).toString()) : 'N/A';
    this._item = data;
  }

  @Input() i: number;
  @Input() qParams: any;

  get item() {
    return this._item;
  }

  public isShowOnBidBoard(item) {
    if (!Utils.isArrayNotEmpty(item.carrierBids)) return 'No';
    const lastBid = item.carrierBids[item.carrierBids.length - 1];
    return lastBid?.isShowOnBidBoard ? 'Yes' : 'No';
  }

  private getDropDate(shipments) {
    let arr = [];
    if (Utils.isArrayNotEmpty(shipments)) {
      for (let order of shipments) {
        if (order.deliveryInfos) {
          for (let info of order.deliveryInfos) {
            if (info.type == Const.TaskType.DROPOFF) {
              this.handleDeliveryDate(info, arr);
            }
          }
        } else {
          // deprecated
          if (Utils.isArrayNotEmpty(order.dropInfo.windows)) {
            let timezone = ((order.dropInfo.addr || {}).metadata || {}).timeZoneStandard;
            for (let item of order.dropInfo.windows) {
              arr.push({ time: item.from, timeTo: item.to, timezone });
            }
          }
        }
      }
    }
    arr.sort((a, b) => a.time < b.time ? 1 : (a.time > b.time ? -1 : 0));
    if (arr.length > 0) {
      return arr[0];
    } else {
      return null;
    }
  }

  private getDeliveryInfoWindows(info) {
    if (Utils.isArrayNotEmpty(info.windows)) {
      return info.windows;
    } else if (info.appointmentInfo) {
      return [{ ...info.appointmentInfo, isAppointment: true }];
    }
    return [];
  }

  private handleDeliveryDate(info, arr) {
    let windows = this.getDeliveryInfoWindows(info);
    if (Utils.isArrayNotEmpty(windows)) {
      let timezone = ((info.addr || {}).metadata || {}).timeZoneStandard;
      for (let item of windows) {
        arr.push({ time: item.from, timeTo: item.to, timezone, isAppointment: item.isAppointment });
      }
    }
  }

  // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
  private displayWindow(obj: { time: string, timeTo: string, timezone: string, isAppointment: boolean }) {
    if (!obj || (!obj.time && !obj.timeTo)) return 'N/A';
    let formatDateTime = 'ddd, MMM D . h:mm a';
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split('.');
    let arr2 = timeTo.split('.');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom} - ${timeTo}`;
    if (obj.isAppointment) {
      str += '\n(Appointment Scheduled)';
    }
    return str;
  }

  getTimeToPickup() {
    let pickupTime = this.item?.pickDt?.time
    if (!pickupTime) return 'N/A'
    let duration = new Date(pickupTime).getTime() - new Date().getTime();
    const isNegative = duration < 0;
    duration = Math.abs(duration);
    let data = this.SplitTime(duration / (1000 * 60 * 60))
    let s = ''
    if (data.days) {
      s = `${data.days ?? 0} days, `
    }
    if (data.hours) {
      s += `${data.hours ?? 0} hours, `
    }
    if (data.minutes) {
      s += `${data.minutes ?? 0} minutes`
    }
    if (isNegative) s = `-${s}`;
    return s
  }

  SplitTime(numberOfHours = 0) {
    var days = Math.floor(numberOfHours / 24);
    var remainder = numberOfHours % 24;
    var hours = Math.floor(remainder);
    var minutes = Math.floor(60 * (remainder - hours));
    return ({ days, hours, minutes })
  }

  getLabelGhostLoad() {
    return this.item.isGhostLoad && this.item.source == WarpConst.JobSources.marketplace ? 'Marketplace' : 'Ghost Load';
  }

  public getFirstShipmentId(shipments = []) {
    if (!shipments || !shipments?.length) {
      return ''
    }
    let shipmentWarpIds = shipments.map(item => this.showShipmentCode(item)).filter(item => !!item)
    if (shipmentWarpIds.length == 0) return ''
    shipmentWarpIds.sort()
    return shipmentWarpIds[0]
  }

  public seeAllOrder() {
    DrawerService.openFormDrawer(SeeAllOrder, {
      nzContentParams: {
        job: this.item,
      },
    });
  }

  public getFirstClientName() {
    return this.item.clients[0].name;
  }

  public getAllClientNames() {
    return this.item.clients.map(client => client.name).join(', ');
  }

  shouldShowEquipmentAndTemp() {
    return this.item.equipments || this.item.tempRange;
  }

  getInfo(item, type) {
    let task;
    if (type == Const.TaskType.PICKUP) {//pickup đầu tiên
      task = item.tasks.filter(t => t.type == Const.TaskType.PICKUP)[0];
    }
    else {//dropoff cuối cùng
      task = item.tasks.slice().reverse().filter(t => t.type == Const.TaskType.DROPOFF)[0];
    }
    return task?.info
  }

  isMarketPlaceLoad() {
    return this.item.isGhostLoad && this.item.source == WarpConst.JobSources.marketplace;
  }

  public getFirstCommodity(item) {
    if (item.totalShipmentsItems?.commodity?.length > 1)
      return `${item.totalShipmentsItems.commodity[0]}, ${item.totalShipmentsItems.commodity[1]}`;
    else if (item.totalShipmentsItems?.commodity?.length)
      return item.totalShipmentsItems.commodity[0];
    else return "N/A";
  }

  public getAllCommodities(item) {
    return item.totalShipmentsItems?.commodity?.join(', ');
  }

  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  public onBtnOnUpdateTargetRate(item) {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: item?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(item, data)
      },
    });
  }

  onReUpdateDatRate(item, event) {
    this.updateDatRate(item, {});
  }

  private updateDatRate(item, params) {
    item.isLoading = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${item._id}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge) {
          this._item.datRate = resp?.data;
        }
        item.isLoading = false;
      }, err => {
        // this.showErr(err);
        item.isLoading = false;
        Log.e(err);
      }
    );
  }

  onReUpdateAvgPastRate(item) {
    this.updateAvgPastRate(item, {});
  }

  private updateAvgPastRate(item, params) {
    item.isLoadingAvg = true;
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${item.id}/update_avg_past_rate`), params).subscribe(
      resp => {
        if (resp?.data) {
          this._item.avgPastRates = InputHelper.formatMoney2(resp?.data?.avgPastRates.toFixed(2).toString());
        }
        item.isLoadingAvg = false;
      }, err => {
        item.isLoadingAvg = false;
        Log.e(err);
      }
    );
  }

  public getBestOffers(item) {
    if (item.carrierBidInfo?.bestOffer) {
      let bestOffers = item.carrierBidInfo.bestOffer;
      if (item.assignedCarrier?.carrierId) {
        for (let i = 0; i < bestOffers.length; i++) {
          bestOffers[i].flagAssigned = bestOffers[i].carrierId == item.assignedCarrier.carrierId;
        }
      }
      return bestOffers;
    } else {
      return [];
    }
  }

  public isCarrierReady(item): boolean {
    const status = item?.carrier?.status;
    return status === WarpConst.CarrierStatus.active;
  }

  public getTooltipCarrierStatus(item): string {
    const status = item?.carrier?.status;
    return `Carrier is ${this.getStatusCarrier(status)}`;
  }

  public onBtnBestOffer(job, offer) {
    if(this.isOnTabNotAcceptLoadTender) return; // view only
    if (this.isMarketPlaceLoad()) return;
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        closeOnSuccess: true,
        job: job,
        bestOffer: offer,
        cost: this.makeCost(offer.price),
        updateSuccess: resp => {
          this.onBtnRefresh.emit();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  private makeCost(price) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: price,
        qty: 1,
        total: price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: price,
      usdConversion: 0
    }
  }

  public getTooltipAssignCarrier() {
    if (this.isMarketPlaceLoad()) return 'This carrier cannot be assigned because relate to Marketplace Order';
    return 'Assign Carrier';
  }

  getNextQueue(item){
    if(!item?.carrierBids?.[0]?.metadata?.nextQueue?.when) return 'N/A';
    return `${moment(item?.carrierBids?.[0]?.metadata?.nextQueue?.when).tz('America/New_York').format('MMM D, h:mm a')} EST`;
  }

  public getSentCount(item) {
    return item.carrierBidInfo?.sendCount || 0;
  }

  public getAcceptanceCount(item) {
    return item.carrierBidInfo?.acceptanceCount || 0;
  }

  public getRefuseCount(item) {
    return item.carrierBidInfo?.refuseCount || 0;
  }

  public getMatchingCount(item) {
    return item.carrierBidInfo?.waitingCount + this.getRefuseCount(item) + this.getAcceptanceCount(item) || 0;
  }

  public formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  public onBtnSendDocs() {
    if (this.isAdminReadOnlyRole) return;
    if (!this.item.carrier) return;
    DialogService.openFormDialog1(SendDocsComponent, {
      nzComponentParams: {
        job: this.item,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-docs-form",
    });
  }

  hasBid(item): boolean {
    return (item.carrierBids ?? []).length > 0;
  }

  public getRouteLinkDetail(item) {
    const carrierBids = (item.carrierBids || []);
    const lastBid = carrierBids[carrierBids.length - 1];
    return [Const.routeAdminCarrierSales, lastBid?.id];
  }

  public onClickCreateCarrierBid(item) {
    if (this.isAdminReadOnlyRole) return;
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/createByJob`, { jobId: item?._id }).subscribe(
      resp => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/${this.routeAdminCarrierSales}/${resp?.data?.id}`])
        );
        this.router.navigate([url]);
      }, err => {
        this.showErr(err);
      }
    );
  }

  public onBtnCarrierSalesRep(item: any) {
    const carrierSalesRepId = item?.carrierSalesRep?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: item.id,
        carrierSalesRepId: carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.refresh();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  public refresh() {
    this.routeWithQueryUrl(Object.assign({}, this.qParams, { loaded: Date.now() }))
  }

  public copyPublicLink(item: any) {
    const lastBid = Utils.cloneObject(item.carrierBids || []).pop();
    const bidId = lastBid?.id;
    const url = `${environment.carrierWebUrl}/public/load-board/${bidId}`;
    Utils.copyTextToClipboard(url, e => {
      if (e) {
        this.showErr('Cannot copy public link.');
      } else {
        this.showSuccess(`Public link was copied successfully. ${url}`);
      }
    });
  }
}